import React from 'react'
import WeDo from '../../components/Whatwedo/Whatwedo';

const home = () => {
  return (
    <>
    <WeDo />
    </>
  )
}

export default home
